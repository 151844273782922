.coverBgImg {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 6vw;
  @include media-breakpoint-up(sm) {
    height: 500px;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      background: linear-gradient(to top, $body-color, transparent);
      opacity: 0.15;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75%;
    }
  }
  .articleHeader {
    color: $white;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      width: 800px;
    }
    .data {
      .post-categories {
        li {
          a {
            background-color: transparent;
            border: 1px solid $white;
            color: $white;
            &:hover {
              color: $secondary;
              background-color: $white;
            }
          }
        }
      }
      .date {
        color: $white;
      }
    }
  }
}
