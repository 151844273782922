.reportArchive {
  background-color: $white;
  padding: 30px;
  box-shadow: $box-shadow;
  border-radius: $border-radius-lg;
  @include media-breakpoint-up(sm) {
    padding: 60px;
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    @include media-breakpoint-up(sm) {
      gap: 60px;
    }
    .reportArchiveSingle {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      @include media-breakpoint-up(sm) {
        gap: 20px;
      }
      @include media-breakpoint-up(lg) {
        width: calc(50% - 30px);
      }
      .img {
        align-self: flex-start;
        position: relative;
        width: calc(50% - 15px);
        overflow: hidden;
        border-radius: $border-radius;
        &::before {
          content: "";
          width: 100%;
          padding-top: 100%; //高さの比率 ÷ 幅の比率 × 100
          display: block;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: $transition-base;
        }
      }
      &:hover {
        color: $primary;
        .img {
          img {
            transform: scale(1.05);
          }
        }
      }
      .cont {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .cat {
          font-size: 10px;
          display: inline-block;
          background-color: tint-color($primary, 80%);
          color: $primary;
          margin-bottom: 10px;
          padding: 7px 12px;
          line-height: 1;
          @include media-breakpoint-up(sm) {
            font-size: 12px;
            padding: 10px 15px;
            margin-bottom: 15px;
          }
        }
        .title {
          font-size: 15px;
          font-weight: normal;
          margin-bottom: 10px;
          @include media-breakpoint-up(sm) {
            font-size: 18px;
            margin-bottom: 15px;
          }
        }
        .description {
          font-size: 10px;
          margin-bottom: 0;
          @include media-breakpoint-up(sm) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    @include media-breakpoint-up(sm) {
      margin-top: 60px;
    }
  }
}
