.sideMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 13px;
  li {
    border-bottom: 1px solid $border-color;
    a {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      width: 100%;
      padding: 15px 0;
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      &:hover {
        background-color: tint-color($secondary, 90%);
      }
      &::before {
        margin-right: 5px;
        font-family: "Font Awesome 5 Pro";
        content: "\f054";
      }
    }
  }
}
