.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px dotted $body-color;
    &:first-child {
      border-top: 1px dotted $body-color;
    }
    a {
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-decoration: none;
      padding: 20px;
      position: relative;
      color: $body-color;
      transition: $transition-base;
      width: 100%;
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        font-size: 15px;
        padding: 30px;
        gap: 15px;
      }
      .date {
      }
      .title {
      }
      &:hover {
        color: $primary;
      }
    }
  }
}
