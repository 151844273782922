.marker {
  display: inline;
  position: relative;
  background: linear-gradient(rgba(#fcf0f6, 1), rgba(#fcf0f6, 1));
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100% 40%;
}
.font-en {
  font-family: $font-family-en;
}
