.secondHeader {
  padding: 60px 6vw 0;
  margin-top: $headerHeightSM;
  @include media-breakpoint-up(sm) {
    padding: 90px 6vw 0;
    margin-top: $headerHeightLG;
  }
  @include media-breakpoint-up(lg) {
    padding: 90px 6vw 0;
  }
  .inner {
    border-bottom: 1px solid $body-color;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    @include media-breakpoint-up(sm) {
      padding-bottom: 60px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .cont {
    .title-en {
      font-size: 36px;
      font-weight: normal;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 20px;
      font-family: $font-family-en;
      letter-spacing: 0.2em;
      line-height: 1;
    }
    .title {
      font-size: 15px;
      font-weight: normal;
      margin: 0;
      line-height: 1;
    }
  }
}
