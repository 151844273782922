.flow {
  .flowSingle {
    background-color: $white;
    padding: 25px;
    display: grid;
    grid-template:
      "number"
      "......" 15px
      "title"
      "......" 15px
      "cont"
      / 100%;
    @include media-breakpoint-up(sm) {
      padding: 30px;
      grid-template:
        "number ... ....." 15px
        "number ... title"
        "number ... ....." 15px
        "number ... cont"
        "number ... ....." 15px
        / 75px 30px 1fr;
    }
    margin-bottom: 80px;
    position: relative;
    &::after {
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\f2ec";
      transform: rotate(180deg);
      position: absolute;
      bottom: -60px;
      text-align: center;
      width: 100%;
      font-size: 24px;
      color: $body-color;
      opacity: 0.75;
    }
    &:last-child {
      margin-bottom: 0;
      &::after {
        display: none;
      }
    }
    .number {
      grid-area: number;
      text-align: center;
      @include media-breakpoint-up(sm) {
        padding: 15px 0;
        padding-right: 30px;
        border-right: 1px solid $border-color;
      }
      .text {
        font-size: 13px;
        line-height: 1;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
      .icon {
        font-size: 32px;
        line-height: 1;
        font-weight: bold;
      }
    }
    .title {
      grid-area: title;
      display: flex;
      justify-content: center;
      font-size: 20px;
      line-height: 1.5;
      margin: 0;
      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }
    }
    .cont {
      grid-area: cont;
      font-size: 15px;
      line-height: 2;
    }
  }
}
