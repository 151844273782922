// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "golden": calc(1 / 1.618 * 100%),
  "silver": calc(1 / 1.414 * 100%),
);
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list
.ratio {
  img.cover {
    object-fit: cover;
  }
  img.contain {
    object-fit: contain;
  }
}
