@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  // font-feature-settings: "palt";
  // letter-spacing: 0.08em;
}

img {
  max-width: 100%;
  height: auto;
}

#app {
  overflow-x: hidden;
}

[v-cloak] {
  display: none;
}

@import "components/header";
@import "components/toggleMenu";
@import "components/layout";
@import "components/hero";
@import "components/topDealer";
@import "components/topAbout";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/secondHero";
@import "components/reportArchive";
@import "components/newsList";
@import "components/cardLink";
@import "components/cta";
@import "components/summary";
@import "components/pidds";
@import "components/outline";
@import "components/digest";
@import "components/article";
@import "components/brandTable";
@import "components/formTable";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/flow";
@import "components/faq";
@import "components/pagination";
@import "components/u";
@import "components/anime";
@import "components/vif";
@import "components/footer";