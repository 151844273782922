$headerHeightSM: 70px;
$headerHeightLG: 90px;
#header {
  position: fixed;
  z-index: $zindex-fixed + 1;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  transition: $transition-base;
  grid-template:
    "... logo ... toggleMenuBtn" #{$headerHeightSM}
    / 15px auto 1fr #{$headerHeightSM};
  @include media-breakpoint-up(sm) {
    grid-template:
      "... logo ... toggleMenuBtn" #{$headerHeightLG}
      / 15px auto 1fr #{$headerHeightLG};
  }
  @include media-breakpoint-up(lg) {
    grid-template:
      "... logo ... gnav ... toggleMenuBtn" #{$headerHeightLG}
      / 15px auto 45px auto 1fr #{$headerHeightLG};
  }
  &.bg {
    background-color: $white;
  }
  .logo {
    grid-area: logo;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 30px;
      width: auto;
      @include media-breakpoint-up(sm) {
        height: 36px;
      }
    }
  }
  .gnav {
    grid-area: gnav;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      height: 100%;
      align-items: center;
      ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;
        font-size: 13px;
        li {
          margin-right: 1.5rem;
          a {
            color: $body-color;
            text-decoration: none;
            display: inline-block;
            padding: 2px 0;
            position: relative;
            &::after {
              width: 100%;
              height: 2px;
              background-color: $body-color;
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              transform: scaleX(0);
              transform-origin: left;
              opacity: 0;
              transition: $transition-base;
            }
            &:hover {
              &::after {
                transform: scaleX(1);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .toggleMenuBtn {
    grid-area: toggleMenuBtn;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background-color: $white;
    color: $body-color;
    text-decoration: none;
    transition: $transition-base;
    .icon {
      width: 20px;
      height: 23px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: calc((23px - 14px) / 2) 0;
      &::before {
        content: "";
        width: 100%;
        height: 2px;
        background-color: $body-color;
        transition: $transition-base;
      }
      .bar {
        width: 16px;
        height: 2px;
        background-color: $body-color;
        transition: $transition-base;
      }
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: $body-color;
        transition: $transition-base;
      }
    }
    &.navMode {
      .icon {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 0;
        }
      }
    }
    &:hover {
      text-decoration: none;
      .icon {
        .bar {
          width: 100%;
        }
      }
    }
  }
}
