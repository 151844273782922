.cta {
  padding: 60px 0;
  background-color: $bg-pink;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
}
