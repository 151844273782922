.narrowThumbnailLink {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 13px;
  li {
    margin-bottom: 10px;
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      .img {
        width: 90px;
        height: 90px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: $transition-base;
        }
      }
      .cont {
        flex: 1;
        padding-left: 10px;
      }
      &:hover {
        background-color: tint-color($secondary, 90%);
        .img {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
