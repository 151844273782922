.sectionHeader {
  margin-bottom: 45px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }
  .title-en {
    font-size: 36px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
    font-family: $font-family-en;
    letter-spacing: 0.2em;
    text-align: center;
    line-height: 1;
  }
  .title {
    font-size: 15px;
    font-weight: normal;
    margin: 0;
    text-align: center;
  }
}
