.cardLink {
  display: block;
  background-color: $white;
  border-radius: $border-radius;
  color: $body-color;
  text-decoration: none;
  overflow: hidden;
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius $border-radius 0 0;
    &::before {
      content: "";
      width: 100%;
      padding-top: 61.8%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
  }
  &.wide {
    .img {
      &::before {
        padding-top: 25.25%; //高さの比率 ÷ 幅の比率 × 100
      }
    }
  }
  .cont {
    text-align: center;
    padding: 1rem;
  }
  &:hover {
    color: $primary;
    .img {
      img {
        transform: scale(1.05);
      }
    }
  }
}
