.hamonAnime {
  position: absolute;
  width: 300px;
  height: auto;
  animation: hamonAnime 6s ease-out 0s infinite;
}
@keyframes hamonAnime {
  0% {
    transform: scale(0.1) rotate(30deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.wave {
  position: absolute;
  &.return {
    transform: rotate(180deg);
  }
}
