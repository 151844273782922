#footer {
  background-color: $primary;
  color: $white;
  padding: 30px 6vw;
  display: grid;
  grid-template:
    "logo"
    "..." 15px
    "finfo"
    "..." 30px
    "contact"
    "..." 15px
    "flink"
    "..." 30px
    "fbottom"
    / 100%;
  @include media-breakpoint-up(sm) {
    padding: 60px 6vw;
    grid-template:
      "logo ... contact"
      ".... ... ......." 15px
      "finfo ... flink"
      ".... ... ......." 15px
      "fbottom fbottom fbottom"
      / auto 1fr 200px;
  }
  .logo {
    grid-area: logo;
    img {
      max-height: 40px;
      width: auto;
    }
  }
  .contact {
    grid-area: contact;
  }
  .flink {
    grid-area: flink;
    font-size: 13px;
    text-align: center;
    a {
      color: $white;
    }
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }
  .finfo {
    grid-area: finfo;
    font-size: 13px;
    p {
      margin: 0;
      &.annotation {
        font-size: 12px;
      }
    }
  }
  .fbottom {
    grid-area: fbottom;
    font-size: 12px;
    p {
      margin: 0;
      &.annotation {
        text-indent: -1em;
        padding-left: 1em;
      }
      &.copyright {
        margin-top: 15px;
        font-size: 10px;
      }
    }
  }
}
