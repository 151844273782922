.digest {
  @include media-breakpoint-up(sm) {
    display: flex;
    .img {
      flex-basis: calc(50% - 3vw);
      align-self: flex-start;
      &.actual {
        align-self: center;
      }
    }
    .cont {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &.reverse {
      flex-direction: row-reverse;
    }
  }
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
    &.actual {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: auto;
      }
    }
  }
  .cont {
    padding: 36px 6vw;
    .title {
      line-height: 1.5;
      margin-bottom: 36px;
      font-weight: normal;
      letter-spacing: 0.1em;
    }
    .text {
      font-size: 13px;
      line-height: 2.5;
      margin-bottom: 36px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
    .moreLink {
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
