.hero {
  position: relative;
  .bg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 85vh;
    @include media-breakpoint-up(sm) {
      padding-right: $headerHeightLG;
      height: 70vh;
    }
    @include media-breakpoint-up(lg) {
      height: 100vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: $headerHeightSM + 45 6vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @include media-breakpoint-up(sm) {
      padding: $headerHeightLG + 45 12vw;
      justify-content: center;
      text-align: left;
    }
    .catchcopy {
      color: $body-color;
      font-size: 18px;
      line-height: 2.5;
      font-weight: normal;
      letter-spacing: 0.2em;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  .scrollIcon {
    display: none;
    @include media-breakpoint-up(sm) {
      display: flex;
    }
    position: absolute;
    bottom: 0;
    right: calc(#{$headerHeightLG} / 2);
    z-index: 2;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    transform: rotate(90deg);
    transform-origin: right bottom;
    color: $body-color;
    &::after {
      width: 60px;
      height: 1px;
      margin-left: 6px;
      background-color: $body-color;
      content: "";
      animation: lineanime 1.5s ease-out 0s infinite;
      transform-origin: left bottom;
    }
  }
}
@keyframes lineanime {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
