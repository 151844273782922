.secondHero {
  position: relative;
  width: 100%;
  height: 50vh;
  margin-top: $headerHeightSM;
  @include media-breakpoint-up(sm) {
    height: 65vh;
    margin-top: $headerHeightLG;
  }
  @include media-breakpoint-up(lg) {
    height: 80vh;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      @include media-breakpoint-up(sm) {
        object-position: center;
      }
    }
  }
  .cont {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 45px 6vw;
    @include media-breakpoint-up(sm) {
      text-align: center;
      align-items: center;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      max-width: 750px;
      right: 0;
      left: auto;
      height: 100%;
      justify-content: center;
      text-align: left;
      align-items: flex-start;
    }
    .subCopy {
      margin: 0;
      font-size: 13px;
      line-height: 1.5;
      font-weight: normal;
      letter-spacing: 0.2em;
      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }
    }
    .catchCopy {
      margin: 0;
      font-size: 18px;
      line-height: 1.5;
      font-weight: normal;
      letter-spacing: 0.2em;
      @include media-breakpoint-up(sm) {
        font-size: 30px;
      }
    }
    .leadCopy {
      margin: 0;
      font-size: 12px;
      line-height: 1.5;
      font-weight: normal;
      letter-spacing: 0.2em;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
