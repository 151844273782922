.topDealer {
  background-color: $gold;
  color: $white;
  position: relative;
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  &::before {
    width: 100%;
    height: 45%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    background-color: $white;
    @include media-breakpoint-up(sm) {
      height: 50%;
    }
  }
  .bg {
    position: absolute;
    bottom: 60%;
    left: 0;
    z-index: 2;
    width: 100%;
    text-align: center;
    img {
      max-height: 300px;
    }
    @include media-breakpoint-up(sm) {
      bottom: 50%;
    }
  }
  .sectionHeader {
    color: $gold;
  }
  .container {
    position: relative;
    z-index: 3;
  }
}
