// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1.25rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color;

$input-btn-padding-y-sm: 0.75rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 1.25rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: $border-width;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-600;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 3px;
$btn-border-radius-sm: 3px;
$btn-border-radius-lg: 3px;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;
// scss-docs-end btn-variables

.btn-more {
  min-width: 200px;
  position: relative;
  &::before {
    content: "";
    width: 15px;
    height: 1px;
    position: absolute;
    top: 50%;
    right: 0;
    background-color: $white;
    transition: $transition-base;
    transform-origin: right;
  }
  &::after {
    content: "";
    width: 15px;
    height: 1px;
    position: absolute;
    top: 50%;
    right: -15px;
    background-color: $danger;
    transition: $transition-base;
    transform-origin: left;
  }
  &:hover {
    &::before {
      transform: scaleX(110%);
    }
    &::after {
      transform: scaleX(90%);
    }
  }
  &.btn-light {
    &::before {
      background-color: $gold;
    }
    &::after {
      background-color: $white;
    }
  }
}
.btn-brand {
  background: linear-gradient(110deg, #e59c96 0%, #de66bd 64.85%, #e87ec4 100%);
  background-size: 150% 150%;
  background-position: center;
  color: $white;
  border-color: $primary;
  transition: $transition-base;
  &:hover {
    // background: linear-gradient(
    //   110deg,
    //   #de66bd 0%,
    //   #de66bd 64.85%,
    //   #de66bd 100%
    // );
    background-position: center 10px;
    border-color: $primary !important;
  }
}
