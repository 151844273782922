.piddsBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px dashed $pidds;
  color: $pidds;
  border-radius: $border-radius-sm;
  text-decoration: none;
  background-color: $white;
  transition: $transition-base;
  @include media-breakpoint-up(sm) {
    padding: 12px;
    gap: 1rem;
  }
  &:hover {
    background-color: tint-color($pidds, 90%);
    color: $pidds;
    border: 1px solid $pidds;
  }
  .img {
    img {
      width: 30px;
      @include media-breakpoint-up(sm) {
        width: 50px;
      }
    }
  }
  .cont {
    font-size: 15px;
    font-weight: bold;
  }
}
